import DefaultMixin from '@/components/leiloes/mixin'
import LeilaoMixin from 'comunicator/src/integrations/vue/mixins/leilao.mixin.js'
import {find} from '@/domain/leiloes/services'
import {USpinnerUloc} from 'uloc-vue'

export default {
  provide: function () {
    return {
      controlador: this
    }
  },
  inject: ['page', 'erplayout'],
  mixins: [DefaultMixin, LeilaoMixin],
  components: {
    USpinnerUloc
  },
  data () {
    return {
      loading: true,
      leilao: this.page.leilao
    }
  },
  computed: {},
  mounted () {
    this.page.setActive('telao')
    this.load()
  },
  destroyed () {
  },
  methods: {
    load () {
      this.loading = true
      find(this.leilao.id)
        .then(({data}) => {
          this.loading = false
          this.leilao = Object.assign({}, this, data)
          if (!data.pregaoAtivo || !data.pregaoAtivo.lote) {
            console.log('Pregão ainda não está ativo')
            return
          }
          this.lote = data.pregaoAtivo.lote
          console.log(data)
        })
        .catch(error => {
          this.loading = false
          this.alertApiError(error)
        })
    }
  },
  meta: {
    title: 'Apresentação do leilão',
    name: 'Apresentação do leilão'
  }
}
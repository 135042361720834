<template>
  <div class="apresentacao-fotos-lote">
    <img v-if="imagem" ref="imagem" :src="imagem">
    <div v-else>Buscando fotos do lote...</div>
  </div>
</template>

<script>
// @TODO: V2.1 - Video
export default {
  name: "ApresentacaoFotos",
  props: {
    fotos: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      imagem: null,
      imagemIndex: 0
    }
  },
  mounted() {
    if (Array.isArray(this.fotos) && this.fotos.length) {
      this.start()
    }
  },
  watch: {
    fotos() {
      this.$nextTick(() => {
        this.start()
      })
    }
  },
  methods: {
    start() {
      window.clearInterval(this.$interval)
      this.$interval = setInterval(() => {
        this.imagemIndex++
        if (typeof this.fotos[this.imagemIndex] === 'undefined') {
          this.imagemIndex = 0
        }
        this.imagem = this.fotos[this.imagemIndex].full || this.fotos[this.imagemIndex].url
      }, 4000)
      this.imagem = this.fotos[0].full || this.fotos[0].url
    }
  }
}
</script>
<script>
import DefaultMixin from '../mixin/mixin.js'
import ApresentacaoFotos from "../include/ApresentacaoFotos"
import * as Status from '../../../../../domain/leiloes/helpers/LoteStatus'
export default {
  name: 'ApresentacaoTelaoBlack1',
  mixins: [DefaultMixin],
  components: {
    ApresentacaoFotos
  },
  data () {
    return {
    }
  },
  computed: {
    colorStatus () { // TODO
      if (this.lote.status === null) {
        return '#000000'
      }
      if (typeof Status.StatusFake[this.lote.status]['color'] !== 'undefined') {
        return Status.StatusFake[this.lote.status]['color'] === '#FFFFFF' ? '#000000' : Status.StatusFake[this.lote.status]['color']
      }
      return '#000000'
    },
    ultimosLances () {
      if (!this.lote.lances || !this.lote.lances.length) {
        return null
      }
      return this.lote.lances.slice(0, 3)
      // return this.lote.lances.sort((a, b) => Number(a.valor) > Number(b.valor))
    }
  }
}
</script>

<template>
  <div v-if="!loading" class="apresentacao-telao black2">
    <div class="col1">
      <div class="lote-info">
        <div class="lote-num">
          <div>LOTE</div>
          <div class="num">{{ lote.numero }}</div>
        </div>
        <div class="lote-desc">
          <div>{{ lote.bem.descricao }}</div>
        </div>
      </div>
      <div class="lote-image">
        <apresentacao-fotos :fotos="fotos" />
      </div>
    </div>
    <div class="col2">
      <div class="data logos">
        <div class="comitente" v-if="lote.bem.comitente">
          <div v-if="lote.bem.comitente.image && lote.bem.comitente.image.thumb"><img width="100%" :src="lote.bem.comitente.image.thumb"></div>
          <div v-else class="font-10 text-center">{{ lote.bem.comitente.pessoa.name }}</div>
        </div>
        <div class="leiloeiro" v-if="leilao.leiloeiro.urlLogomarca">
          <img width="100%" :src="leilao.leiloeiro.urlLogomarca">
        </div>
      </div>
      <div class="data">
        <div class="title">Lance inicial</div>
        <div class="value">{{ lote.valorInicial | moeda }}</div>
      </div>

      <div class="data">
        <div class="title">Lance Atual</div>
        <div class="value" v-if="ultimoLance">{{ valorAtual | moeda }}</div>
        <div class="value" v-else>-</div>
      </div>

      <div class="data">
        <div class="title">Tempo</div>
        <div class="value">{{timerPregaoFormatado}}</div>
      </div>

      <div class="data">
        <div class="title">Status</div>
        <div class="value" :class="'lote-status scolor lote-status-' + lote.status"><span :style="'color: ' + colorStatus">{{ loteStatusString }}</span></div>
      </div>

      <div class="data">
        <div class="title">Últimos Lances</div>
        <div class="historicoLances">
          <table>
            <thead>
            <tr>
              <th>Autor</th>
              <th>Data</th>
              <th>Valor</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="lance in ultimosLances" :key="'lance-' + lance.id">
              <td>{{lance.autor.apelido}}</td>
              <td>{{lance.data|formatDate}}</td>
              <td>{{lance.valor|moeda}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="apresentacao-telao black1 flex flex-center items-center column h-full no-select" style="justify-content: center !important" v-else>
    <div class="m-b-xs">
      <u-spinner-uloc />
    </div>
    Carregando a aplicação
  </div>
</template>

<style src="./estilo2.styl" lang="stylus" />
